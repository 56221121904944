import * as React from "react";
import { useDineInListQuery, useGetDineInCategoriesQuery } from "../../generated/graphql";
import { groupBy } from "lodash";
import { useAuth } from "../../store";

export const useMenuItem = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data, loading } = useDineInListQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });
  const {
    data: Categories,
  } = useGetDineInCategoriesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: guest?.property_id,
    },
  });

  return {
    data,
    loading,
    Categories:Categories?.getDineInCategories
  };
};

const useRoomDining = () => {
  const { data, loading ,Categories} = useMenuItem();

  const menuItems = React.useMemo(() => {
    if (!data) return { categories: [], foodMenu: [] };

    const groupedMenu = groupBy(data?.getGuestEdibles, (data: any) => data.type);
    console.log(groupedMenu);
    
    const categories = Object.keys(groupedMenu);
    const iterable = Categories?.map((category) => {
      console.log("categpry",category);
      
      return {
        description:category?.desc,
        name: category?.name,
        items: groupedMenu[category?.name||''],
      };
      
    });


    return {
      categories,
      foodMenu: iterable,
    };
  }, [data,Categories]);

  return {
    loading,
    ...menuItems,
  };
};

export default useRoomDining;
