import * as React from "react";

import {
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { useDomain } from "../../utils";

const useHotelInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  const { dir_con, about, hotel_services_image, rooms_image , directory_description} =
    propertyMeta?.getMeta || {};

  return {
    about,
    dir_con,
    directory_description,
    phoneNumber: dir_con?.phone,
    propertyDetails,
    hotelRoomsImage: rooms_image,
    hotelServicesImage: hotel_services_image,
    loading: loadingMeta || loadingProperty,
  };
};

export default useHotelInfo;
