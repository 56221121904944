import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import * as React from 'react';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useTheme from '@mui/material/styles/useTheme';
import withReactContent from 'sweetalert2-react-content';

import { ServiceRequestIcon, SubmissionSuccessIcon } from '../../components';
import { CustomButton } from '../../view/InRoomDining';

const DEFAULT_OPTIONS = {
  padding: 12,
  showCancelButton: false,
  showConfirmButton: false
};

const STOP_CLOSING_OPTION = {
  allowEscapeKey: false,
  allowOutsideClick: false
};

export const useSweetAlert = () => {
  const isMobileScreen = useMediaQuery('(max-width:480px)');

  const sweetAlert = React.useMemo(() => {
    const SwalWithDefaultOptions = Swal.mixin({
      ...DEFAULT_OPTIONS,
      width: isMobileScreen ? '90vw' : 400
    });
    return withReactContent(SwalWithDefaultOptions);
  }, [isMobileScreen]);

  return sweetAlert;
};

export const useServiceAlert = (onCloseHandlers: () => void) => {
  const theme = useTheme();
  const sweetAlert = useSweetAlert();

  const html = (message: string) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <SubmissionSuccessIcon
        sx={{ height: 60, width: 60 }}
        customColor={theme.palette.primary.main}
      />
      <Typography
        variant="h6"
        sx={{ mt: 2, fontWeight: 600, textAlign: 'center' }}
      >
        {message}
      </Typography>

      <Button
        variant="text"
        sx={{
          mt: 1,
          flex: 1,
          boxShadow: 'none',
          '&:hover': { boxShadow: 'none' }
        }}
        onClick={() => {
          onCloseHandlers();
          sweetAlert.close();
        }}
      >
        Go back
      </Button>
    </Box>
  );

  return (title: string) =>
    sweetAlert.fire({ html: html(title), ...STOP_CLOSING_OPTION });
};

export const useQuickServiceAlert = () => {
  const theme = useTheme();
  const sweetAlert = useSweetAlert();
  const [serviceCount, setServiceCount] = React.useState(1);

  const html = (service: any) => {
    const canAddMultiple = Boolean(service.qty_by_guest);
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <ServiceRequestIcon
          sx={{ height: 60, width: 60 }}
          customColor={theme.palette.primary.main}
        />
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            fontWeight: 600,
            textAlign: 'center',
            fontSize: theme => theme.spacing(2.25)
          }}
        >
          Make request for {service.task}
        </Typography>

        {canAddMultiple && (
          <ButtonGroup
            size="small"
            variant="contained"
            sx={{
              mt: 2,
              border: 'none',
              color: '#ffffff',
              alignSelf: 'center',
              backgroundColor: 'primary.main'
            }}
          >
            <IconButton
              size="small"
              onClick={() => setServiceCount(value => value + 1)}
              sx={{ color: '#ffffff' }}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
            <CustomButton
              size="small"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              sx={{ border: 'none !important' }}
            >
              {serviceCount}
            </CustomButton>

            <IconButton
              size="small"
              sx={{ color: '#ffffff' }}
              onClick={() =>
                setServiceCount(value => (value >= 2 ? value - 1 : value))
              }
            >
              <RemoveIcon fontSize="inherit" />
            </IconButton>
          </ButtonGroup>
        )}

        <Box sx={{ mt: 3, width: '100%', display: 'flex' }}>
          <Button
            variant="text"
            sx={{
              flex: 1,
              boxShadow: 'none',
              textTransform: 'capitalize',
              '&:hover': { boxShadow: 'none' }
            }}
            onClick={() => {
              sweetAlert.close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              ml: 1,
              flex: 1,
              boxShadow: 'none',
              textTransform: 'capitalize',
              '&:hover': { boxShadow: 'none' }
            }}
            onClick={() => {
              sweetAlert.clickConfirm();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    );
  };

  return (service: any) =>
    sweetAlert.fire({
      html: html(service),
      preConfirm: () => [serviceCount],
      ...STOP_CLOSING_OPTION
    });
};
