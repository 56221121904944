import {
  Box,
  Menu,
  Button,
  styled,
  MenuItem,
  Typography,
  IconButton,
  Paper as MiUiPaper,
  SxProps,
  Theme,
  ButtonGroup,
} from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCart } from '../../store';
import { imageFallback } from '../../utils';
import { startCase, camelCase } from 'lodash';
import useRoomDining from './room-dining-hook';
import { FullScreenLoader } from '../../components';
import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import useInfo from '../Dashboard/components/InfoCard';
import DineInDialog from '../../components/DineInDialog';
import { ALLERGEN_ICONS } from '../../constant/services';

const ALL_MENU = 'ALL_MENU';

interface HeaderLayoutProps {
  title: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export const HeaderLayout = ({ sx, title, children }: HeaderLayoutProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow:
          '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
        ...(sx ? sx : {})
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton disableRipple onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 1, fontSize: '13px' }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
interface HeaderProps {
  categories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
}

const Header = (props: HeaderProps) => {
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, currentCategory, onClick } = props;

  return (
    <HeaderLayout title="In Room Dining">
      <Button
        onClick={handleClick}
        sx={{ color: 'text.primary' }}
        endIcon={openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {startCase(camelCase(currentCategory))}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {categories.map(category => (
          <MenuItem
            key={category}
            onClick={() => {
              onClick && onClick(category);
              handleClose();
            }}
          >
            {startCase(camelCase(category))}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </HeaderLayout>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { roomID } = useParams();
  const cart = useCart((state: any) => state.cart);
  const totalItems = cart.reduce(
    (prev: number, current: any) => prev + current.count,
    0
  );

  if (totalItems === 0) return null;

  return (
    <Box sx={{ p: 1 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(`/${roomID}/cart`)}
      >
        View Cart ({totalItems} Items)
      </Button>
    </Box>
  );
};

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  border: '1px solid #F0F0F0',
  boxShadow: 'none',
  borderRadius: theme.spacing(1.875)
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  paddingRight: '4px !important',
  paddingLeft: '4px !important',
  borderRadius: '5px',
  fontSize: '13px'
}));

interface LayoutProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

// Using position fixed because of chrome mobile's moving url bar's behavior
// https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile

export const Layout = ({ children, sx }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: '100%',
        minHeight: 'unset',
        flexDirection: 'column',
        backgroundColor: 'background.light',
        ...(sx ? sx : {})
      }}
    >
      {children}
    </Box>
  );
};

interface FoodItemCardProps {
  item: any;
}

export const FoodItemCard = ({ item }: FoodItemCardProps) => {
  const { currency } = useInfo();
  const { name, price, img, id, isVegan, desc,allergens} = item;    
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const cart = useCart((state: any) => state.cart);
  const addToCart = useCart((state: any) => state.addToCart);
  const removeFromCart = useCart((state: any) => state.removeFromCart);

  const itemsInCart = React.useMemo(() => {
    const currentItems = cart.filter((item: any) => item.id === id);
    return currentItems.reduce((acc: number, curr: any) => acc + curr.count, 0);
  }, [cart, id]);

  const toggleDrawer = () => setOpen(state => !state);

  const addToCartWrapper = (id: string) => {
    if (item?.extraOptions?.length) {
      toggleDrawer();
    } else {
      addToCart(id);
    }
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
     { img ? (<Box
        src={img}
        alt={name}
        width={70}
        height={70}
        component="img"
        onError={imageFallback}
        style={{ borderRadius: '10px', objectFit: 'cover' }}
        onClick={handleOpen}
      />):("")}
      <Box sx={{ ml: 1.2, flex: 1 }} onClick={handleOpen}>
        <Typography variant="body2">{name}</Typography>
      {desc &&  <Typography variant="body2" sx={{ color: 'text.secondary',fontStyle:"italic" }}>
          {desc.slice(0, 40)}...
        </Typography>}
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {currency} {price}
        </Typography>
        {/* <VegNonIcon /> */}
        <Box sx={{display:"flex"}}>
          {
          allergens?.map((all:any)=>{
            const icon = (ALLERGEN_ICONS as any)[all];
            return (<Typography sx={{pr:1}}>{icon}</Typography>)
          })
        }
        </Box>

        <Box
          component="img"
          onError={imageFallback}
          style={{ marginTop: '4px' }}
          alt={isVegan ? 'veg' : 'non-veg'}
          src={`/assets/${isVegan ? 'veg' : 'non-veg'}.png`}
        />
      </Box>
      {!Boolean(itemsInCart) ? (
        <CustomButton
          size="small"
          variant="outlined"
          sx={{ alignSelf: 'center' }}
          onClick={() => addToCartWrapper(id)}
        >
          Add
        </CustomButton>
      ) : (
        <ButtonGroup
          size="small"
          variant="contained"
          sx={{
            border: 'none',
            color: '#ffffff',
            alignSelf: 'center',
            backgroundColor: 'primary.main'
          }}
        >
          <IconButton
            size="small"
            onClick={() => addToCartWrapper(id)}
            sx={{ color: '#ffffff' }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <CustomButton
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{ border: 'none !important' }}
          >
            {itemsInCart}
          </CustomButton>

          <IconButton
            size="small"
            onClick={() => removeFromCart(id)}
            sx={{ color: '#ffffff' }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </ButtonGroup>
      )}

      {item?.extraOptions?.length && (
        <ExtraItemDrawer
          open={open}
          currentItem={item}
          toggleDrawer={toggleDrawer}
        />
      )}

      <DineInDialog
        desc={desc}
        handleDialogClose={handleClose}
        image={img}
        open={openDialog}
        title={name}
        price={price}
        icon={isVegan}
        currency={currency || ''}
      />
    </Paper>
  );
};

// TODO: Extract All component above into a separate folder
const InRoomDining = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const [currentCategory, setCurrentCategory] = React.useState(
    category || ALL_MENU
  );
  const { loading, foodMenu, categories } = useRoomDining();
  const foodCategories = [ALL_MENU, ...categories];
  const filteredMenu = React.useMemo(() => {
    if (currentCategory === ALL_MENU) return foodMenu;
    return foodMenu?.filter(item => item.name === currentCategory);
  }, [currentCategory, foodMenu]);

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        categories={foodCategories}
        onClick={setCurrentCategory}
        currentCategory={currentCategory}
      />
      <Box
        sx={{
          padding: '5px',
          flex: 1,
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {/* Header */}
        {filteredMenu?.map((menu, foodMenuIndex) => {
          console.log("menu",menu);
          
          return (
            <React.Fragment key={menu.name}>
              <Box
                sx={{
                  mt: foodMenuIndex === 0 ? 1 : 3,
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography sx={{fontWeight:"bold"}} variant="body2">
                  {startCase(camelCase(menu.name))}
                </Typography>
                <Typography variant="body2">
                  {menu?.items?.length} Items
                </Typography>
              </Box>
              <Typography sx={{mb:1}} variant="body2">
                  {menu.description}
                </Typography>
              <Box>
                {menu?.items?.map(item => (
                  <FoodItemCard key={item?.id} item={item} />
                ))}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Footer />
    </Layout>
  );
};

export default InRoomDining;
