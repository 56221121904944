import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
} from '@mui/material';
import * as React from 'react';

interface props {
  open: boolean;
  title: string;
  desc: string;
  image: string;
  price: number;
  icon: string;
  currency: string;
  handleDialogClose: () => void;
}

const DineInDialog = ({
  open,
  handleDialogClose,
  title,
  desc,
  image,
  price,
  icon,
  currency
}: props) => {
  return (
    <>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle
          color={'#fff'}
          bgcolor={theme => theme.palette.primary.main}
        >
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton color={'inherit'} onClick={handleDialogClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        {image?(<Box style={{ padding: '10px' }}>
          <img
            alt=''
            width={'320px'}
            height={'320px'}
            src={image}
            style={{ objectFit: 'cover', borderRadius: '10px' }}
          />
        </Box>):("")}

        <DialogContent sx={{ flex: 1, p: 1.5, width: '300px' }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {currency} {price}
          </Typography>
          <Box
            component="img"
            style={{ marginTop: '4px' }}
            alt={'veg'}
            src={`/assets/${icon ? 'veg' : 'non-veg'}.png`}
          ></Box>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {desc}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DineInDialog;
