import { Close } from '@mui/icons-material';
import { Box, IconButton, SwipeableDrawer, Typography } from '@mui/material';

import { imageFallback } from '../../utils';

type DrawerProps = {
  name: string;
  desc: string;
  image: string;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

function Drawer(props: DrawerProps) {
  const { name, open, desc, image, handleOpen, handleClose } = props;

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          pt: 0.5,
          pr: 3,
          pl: 3,
          overflow: 'auto',
          maxHeight: '60vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Box marginLeft="96%">
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        {image ? (
          <Box
            alt={name}
            src={image}
            component="img"
            onError={imageFallback}
            sx={{ height: 250, width: '100%', objectFit: 'cover' }}
          />
        ) : (
          <Box
            alt={name}
            src="/assets/no.jpg"
            component="img"
            sx={{
              height: 250,
              width: 200,
              marginLeft: '45%',
              objectFit: 'cover'
            }}
          />
        )}

        <Typography variant="h6" sx={{ fontSize: theme => theme.spacing(2.5) }}>
          {name}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{desc}</Typography>
      </Box>
    </SwipeableDrawer>
  );
}

export { Drawer };
