import {
  Box,
  Button,
  Checkbox,
  Typography,
  SwipeableDrawer,
  FormControlLabel
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { useCart } from '../../store';
import { DineInItem } from '../../generated/graphql';
import useInfo from '../../view/Dashboard/components/InfoCard';

interface ExtraItemDrawerProps {
  open: boolean;
  currentItem: DineInItem;
  toggleDrawer: () => void;

  extraItems?: any;
  buttonText?: string;
  updateCartItemExtra?: any;
}

function ExtraItemDrawer({
  open,
  currentItem,
  toggleDrawer,

  buttonText,
  extraItems,
  updateCartItemExtra
}: ExtraItemDrawerProps) {
  const {currency}=useInfo()
  const { control, register, getValues, setValue } = useForm();
  const { remove } = useFieldArray({ control, name: 'extras' });
  const addToCartStore = useCart((state: any) => state.addToCart);

  useEffect(() => {
    remove();
  }, [currentItem,remove]);

  //   Updates value
  useEffect(() => {
    if (!extraItems || !currentItem) return;

    const indexArr = extraItems.map((item: any) =>
      currentItem.extraOptions?.findIndex(
        extraOption => extraOption?.name === item.name
      )
    );

    indexArr.forEach((item: number) => {
      setValue(`extras.${item}.checked`, true);
    });
  }, [setValue, extraItems, currentItem]);

  const addToCart = () => {
    const extraItems = getValues()
      .extras.filter(({ checked }: any) => Boolean(checked))
      .map(({ checked, ...rest }: any) => ({ ...rest }));

    if (!updateCartItemExtra) {
      if (isEmpty(extraItems)) {
        addToCartStore(currentItem.id);
      } else {
        addToCartStore(currentItem.id, extraItems);
      }
    } else {
      updateCartItemExtra(extraItems);
    }

    toggleDrawer();
  };

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
    >
      <Box
        sx={{
          p: 2,
          overflow: 'auto',
          maxHeight: '50vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {Boolean(currentItem?.extraOptions) &&
          currentItem.extraOptions?.map((extraItem: any, index: number) => (
            <Box
              key={index}
              sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
                justifyContent: 'space-between'
              }}
            >
              <input
                type="hidden"
                value={extraItem.name}
                {...register(`extras.${index}.name`)}
              />
              <input
                type="number"
                value={extraItem.price}
                style={{ display: 'none' }}
                {...register(`extras.${index}.price`)}
              />
              <Typography variant="body2" sx={{ flex: 3 }}>
                {extraItem.name}
              </Typography>
              <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                {currency} {extraItem.price}
              </Typography>
              <Box sx={{ flex: 1, textAlign: 'right' }}>
                {/* <Checkbox {...register(`extras.${index}.checked`)} /> */}

                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      defaultValue={false}
                      name={`extras.${index}.checked`}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={false}
                />
              </Box>
            </Box>
          ))}
      </Box>
      {/* Drawer Footer */}
      <Box sx={{ p: 1 }}>
        <Button fullWidth variant="contained" onClick={() => addToCart()}>
          {buttonText || 'Add to cart'}
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

export { ExtraItemDrawer };
