import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: 'About';
  desc: Scalars['String'];
  img: Scalars['String'];
};

export type AboutInput = {
  desc: Scalars['String'];
  img: Scalars['String'];
};

export type Allergens = {
  __typename?: 'Allergens';
  icon: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type AllergensInput = {
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  dineInItem_id: Scalars['String'];
  dineInItem_name: Scalars['String'];
  dineInItem_price: Scalars['Float'];
  extras?: Maybe<Array<Maybe<ExtraOptions>>>;
  quantity: Scalars['Int'];
};

export type CartInput = {
  dineInItem_id: Scalars['String'];
  dineInItem_name: Scalars['String'];
  dineInItem_price: Scalars['Float'];
  extras?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  quantity: Scalars['Int'];
};

export enum Days {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DineInCategories = {
  __typename?: 'DineInCategories';
  desc?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type DineInCategoriesInput = {
  desc?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type DineInItem = {
  __typename?: 'DineInItem';
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  availabletime?: Maybe<Scalars['String']>;
  desc: Scalars['String'];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars['ID'];
  img: Scalars['String'];
  isVegan: Scalars['Boolean'];
  is_Activated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  property_id: Scalars['String'];
  type: Scalars['String'];
};

export type DineInItemInput = {
  allergens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  availabletime?: InputMaybe<Scalars['String']>;
  desc: Scalars['String'];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  isVegan: Scalars['Boolean'];
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  property_id: Scalars['String'];
  type: Scalars['String'];
};

export type Dining = {
  __typename?: 'Dining';
  desc: Scalars['String'];
  id: Scalars['ID'];
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type DiningInput = {
  desc: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type DirectionsAndContact = {
  __typename?: 'DirectionsAndContact';
  address: Scalars['String'];
  email: Scalars['String'];
  img: Scalars['String'];
  phone: Scalars['String'];
};

export type DirectionsAndContactInput = {
  address: Scalars['String'];
  email: Scalars['String'];
  img: Scalars['String'];
  phone: Scalars['String'];
};

export type ExtraOptions = {
  __typename?: 'ExtraOptions';
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type ExtraOptionsInput = {
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FeedbackInput = {
  feedback: Scalars['String'];
  feedback_type_id: Scalars['Int'];
  property_id: Scalars['String'];
  room_id: Scalars['Int'];
};

export type HotelService = {
  __typename?: 'HotelService';
  desc: Scalars['String'];
  id: Scalars['ID'];
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type HotelServiceInput = {
  desc: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type MetaData = {
  __typename?: 'MetaData';
  about: About;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dining_image?: Maybe<Scalars['String']>;
  dir_con: DirectionsAndContact;
  directory_description?: Maybe<Scalars['String']>;
  feedback_desc?: Maybe<Scalars['String']>;
  hotel_name: Scalars['String'];
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  hotel_services_image?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  promotion_desc?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  rooms_image?: Maybe<Scalars['String']>;
};

export type MetaDataInput = {
  about: AboutInput;
  dining_image?: InputMaybe<Scalars['String']>;
  dir_con: DirectionsAndContactInput;
  directory_description?: InputMaybe<Scalars['String']>;
  feedback_desc?: InputMaybe<Scalars['String']>;
  hotel_name: Scalars['String'];
  hotel_services_image?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  promotion_desc?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  rooms_image?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder?: Maybe<Scalars['String']>;
  changeOrderStatus?: Maybe<Scalars['String']>;
  createFeedback?: Maybe<Response>;
  deleteAllergens?: Maybe<Scalars['String']>;
  deleteDineInCategory?: Maybe<Scalars['String']>;
  deleteDining?: Maybe<Scalars['String']>;
  deleteEdible?: Maybe<Scalars['String']>;
  deleteHotelService?: Maybe<Scalars['String']>;
  deleteOrder?: Maybe<Scalars['String']>;
  deletePromotion?: Maybe<Scalars['String']>;
  deleteProperty?: Maybe<Property>;
  deleteRoom?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  requestService?: Maybe<Response>;
  requestServices?: Maybe<Response>;
  updateEdibleStatus?: Maybe<DineInItem>;
  updatePromotionStatus?: Maybe<Promotion>;
  upsertAllergens?: Maybe<Allergens>;
  upsertApiKey?: Maybe<Scalars['String']>;
  upsertDineInCategory?: Maybe<DineInCategories>;
  upsertDining?: Maybe<Scalars['String']>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars['String']>;
  upsertMetaData?: Maybe<Scalars['String']>;
  upsertOrder?: Maybe<Order>;
  upsertPromotion?: Maybe<Promotion>;
  upsertProperty?: Maybe<Property>;
  upsertRoom?: Maybe<Room>;
  upsertUser?: Maybe<User>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars['String'];
  orderStatus: Status;
  propertyID: Scalars['String'];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationDeleteAllergensArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteDineInCategoryArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteDiningArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeletePromotionArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeletePropertyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoomArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationRequestServicesArgs = {
  servicesInput?: InputMaybe<Array<ServiceInput>>;
};

export type MutationUpdateEdibleStatusArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MutationUpdatePromotionStatusArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MutationUpsertAllergensArgs = {
  allergensInput?: InputMaybe<AllergensInput>;
};

export type MutationUpsertApiKeyArgs = {
  apiKey: Scalars['String'];
  propertyID: Scalars['String'];
};

export type MutationUpsertDineInCategoryArgs = {
  dineInCategoriesInput?: InputMaybe<DineInCategoriesInput>;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPromotionArgs = {
  promotionInput: PromotionInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars['String'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  cart_items?: Maybe<Array<Cart>>;
  created_at: Scalars['String'];
  guest_id: Scalars['Int'];
  guest_name: Scalars['String'];
  id: Scalars['ID'];
  order_id: Scalars['String'];
  property_id: Scalars['String'];
  room_id: Scalars['Int'];
  status?: Maybe<Status>;
  total: Scalars['Float'];
  updated_at?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  created_at?: InputMaybe<Scalars['String']>;
  guest_id: Scalars['Int'];
  guest_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  order_id?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  room_id: Scalars['Int'];
  status?: InputMaybe<Status>;
  total: Scalars['Float'];
};

export type Promotion = {
  __typename?: 'Promotion';
  banner_image: Scalars['String'];
  description: Scalars['String'];
  end_date: Scalars['String'];
  id: Scalars['ID'];
  is_Activated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  start_date: Scalars['String'];
};

export type PromotionInput = {
  banner_image: Scalars['String'];
  description: Scalars['String'];
  end_date: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  start_date: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  background_color?: Maybe<Scalars['String']>;
  chat_api_url: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  hotlync_api_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  img: Scalars['String'];
  name: Scalars['String'];
  primary_color: Scalars['String'];
  primary_light: Scalars['String'];
  primary_text_color: Scalars['String'];
  secondary_text_color: Scalars['String'];
};

export type PropertyInput = {
  background_color: Scalars['String'];
  chat_api_url: Scalars['String'];
  currency: Scalars['String'];
  domain: Scalars['String'];
  hotlync_api_url: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  name: Scalars['String'];
  primary_color: Scalars['String'];
  primary_light: Scalars['String'];
  primary_text_color: Scalars['String'];
  secondary_text_color: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAdminPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getAllergens?: Maybe<Array<Maybe<Allergens>>>;
  getDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getGuest?: Maybe<Response>;
  getGuestEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getGuestPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getMeta?: Maybe<MetaData>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getProperty?: Maybe<Property>;
  getPropertyByDomain?: Maybe<Property>;
  getQuickServices?: Maybe<QuickServices>;
  getServices?: Maybe<ServiceByDeptmt>;
  getTaskHistory?: Maybe<Array<Maybe<TaskHistory>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
};

export type QueryGetAdminPromotionsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetAllergensArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetDineInCategoriesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetGuestEdiblesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
};

export type QueryGetGuestPromotionsArgs = {
  currDate: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars['String'];
};

export type QueryGetPromotionsArgs = {
  currDate: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetPropertyByDomainArgs = {
  domain: Scalars['String'];
};

export type QueryGetQuickServicesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetTaskHistoryArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars['String'];
  fileType: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QuickService = {
  __typename?: 'QuickService';
  department: Scalars['String'];
  id: Scalars['String'];
  qty_by_guest: Scalars['Int'];
  task: Scalars['String'];
};

export type QuickServices = {
  __typename?: 'QuickServices';
  code: Scalars['Int'];
  quicktasklist?: Maybe<Array<Maybe<QuickService>>>;
};

export type Response = {
  __typename?: 'Response';
  code: Scalars['String'];
  guest_id?: Maybe<Scalars['Int']>;
  guest_name?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  property_id?: Maybe<Scalars['String']>;
};

export type Room = {
  __typename?: 'Room';
  desc: Scalars['String'];
  id: Scalars['ID'];
  img: Scalars['String'];
  property_id: Scalars['String'];
  room_type: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
};

export type RoomInput = {
  desc: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  property_id: Scalars['String'];
  room_type: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['Int'];
  task: Scalars['String'];
};

export type ServiceByDeptmt = {
  __typename?: 'ServiceByDeptmt';
  code: Scalars['Int'];
  tasklist?: Maybe<Scalars['AWSJSON']>;
};

export type ServiceInput = {
  property_id: Scalars['String'];
  quantity: Scalars['Int'];
  room_id: Scalars['Int'];
  task_id: Scalars['Int'];
};

export type SignedUrl = {
  __typename?: 'SignedURL';
  presigned_upload_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum Status {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type TaskHistory = {
  __typename?: 'TaskHistory';
  created_time?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  task_list?: Maybe<Scalars['Int']>;
  task_name?: Maybe<Scalars['String']>;
};

export enum Types {
  Alcohol = 'ALCOHOL',
  Breakfast = 'BREAKFAST',
  Desserts = 'DESSERTS',
  Drinks = 'DRINKS',
  HotDrinks = 'HOT_DRINKS',
  Mains = 'MAINS',
  Salads = 'SALADS',
  Soups = 'SOUPS',
  Starters = 'STARTERS'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  is_authorized?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  reports_to?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  shift_end?: Maybe<Scalars['String']>;
  shift_start?: Maybe<Scalars['String']>;
  working_days?: Maybe<Array<Maybe<Days>>>;
};

export type UserInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_authorized?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  role?: InputMaybe<UserRole>;
  shift_end?: InputMaybe<Scalars['String']>;
  shift_start?: InputMaybe<Scalars['String']>;
  working_days?: InputMaybe<Array<InputMaybe<Days>>>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Restaurant = 'RESTAURANT'
}

export type GetDineInCategoriesQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetDineInCategoriesQuery = {
  __typename?: 'Query';
  getDineInCategories?: Array<{
    __typename?: 'DineInCategories';
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
  } | null> | null;
};

export type DineInListQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type DineInListQuery = {
  __typename?: 'Query';
  getGuestEdibles?: Array<{
    __typename?: 'DineInItem';
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan: boolean;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    is_Activated?: boolean | null;
    extraOptions?: Array<{
      __typename?: 'ExtraOptions';
      name: string;
      price?: number | null;
    } | null> | null;
  } | null> | null;
};

export type FeedbackListQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type FeedbackListQuery = {
  __typename?: 'Query';
  getFeedbackTypeList?: Array<{
    __typename?: 'Feedback';
    id: number;
    name: string;
  } | null> | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: FeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  __typename?: 'Mutation';
  createFeedback?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type HotelDetailsQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type HotelDetailsQuery = {
  __typename?: 'Query';
  getMeta?: {
    __typename?: 'MetaData';
    promotion_desc?: string | null;
    feedback_desc?: string | null;
    hotel_services_image?: string | null;
    rooms_image?: string | null;
    directory_description?: string | null;
    dining_image?: string | null;
    about: { __typename?: 'About'; desc: string; img: string };
    dining?: Array<{
      __typename?: 'Dining';
      desc: string;
      id: string;
      img: string;
      name: string;
    } | null> | null;
    dir_con: {
      __typename?: 'DirectionsAndContact';
      address: string;
      email: string;
      img: string;
      phone: string;
    };
    hotel_rooms?: Array<{
      __typename?: 'Room';
      desc: string;
      id: string;
      img: string;
      room_type: string;
    }> | null;
    hotel_services?: Array<{
      __typename?: 'HotelService';
      desc: string;
      id: string;
      img: string;
      name: string;
    }> | null;
  } | null;
};

export type CreateOrderMutationVariables = Exact<{
  orderInput: OrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  upsertOrder?: { __typename?: 'Order'; id: string } | null;
};

export type MyOrdersQueryVariables = Exact<{
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
}>;

export type MyOrdersQuery = {
  __typename?: 'Query';
  getGuestOrders?: Array<{
    __typename?: 'Order';
    property_id: string;
    id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    guest_id: number;
    guest_name: string;
    created_at: string;
    order_id: string;
    cart_items?: Array<{
      __typename?: 'Cart';
      dineInItem_id: string;
      quantity: number;
      dineInItem_name: string;
      dineInItem_price: number;
      extras?: Array<{
        __typename?: 'ExtraOptions';
        name: string;
        price?: number | null;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type GetGuestPromotionsQueryVariables = Exact<{
  propertyID: Scalars['String'];
  currDate: Scalars['String'];
}>;

export type GetGuestPromotionsQuery = {
  __typename?: 'Query';
  getGuestPromotions?: Array<{
    __typename?: 'Promotion';
    id: string;
    name: string;
    end_date: string;
    start_date: string;
    property_id: string;
    description: string;
    banner_image: string;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type PropertyDetailsQueryVariables = Exact<{
  domain: Scalars['String'];
}>;

export type PropertyDetailsQuery = {
  __typename?: 'Query';
  getPropertyByDomain?: {
    __typename?: 'Property';
    id: string;
    name: string;
    img: string;
    primary_color: string;
    primary_light: string;
    primary_text_color: string;
    secondary_text_color: string;
    chat_api_url: string;
    currency?: string | null;
    background_color?: string | null;
  } | null;
};

export type PropertyServicesQueryVariables = Exact<{
  property_id: Scalars['String'];
}>;

export type PropertyServicesQuery = {
  __typename?: 'Query';
  getServices?: {
    __typename?: 'ServiceByDeptmt';
    code: number;
    tasklist?: any | null;
  } | null;
};

export type PropertyServicesMutationMutationVariables = Exact<{
  input: ServiceInput;
}>;

export type PropertyServicesMutationMutation = {
  __typename?: 'Mutation';
  requestService?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type RequestServicesMutationVariables = Exact<{
  input?: InputMaybe<Array<ServiceInput> | ServiceInput>;
}>;

export type RequestServicesMutation = {
  __typename?: 'Mutation';
  requestServices?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type GetTaskHistoryQueryVariables = Exact<{
  propertyID: Scalars['String'];
  guestID: Scalars['Int'];
  roomID: Scalars['String'];
}>;

export type GetTaskHistoryQuery = {
  __typename?: 'Query';
  getTaskHistory?: Array<{
    __typename?: 'TaskHistory';
    task_list?: number | null;
    task_name?: string | null;
    status?: string | null;
    quantity?: number | null;
    created_time?: string | null;
  } | null> | null;
};

export type PropertyQuickServicesQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type PropertyQuickServicesQuery = {
  __typename?: 'Query';
  getQuickServices?: {
    __typename?: 'QuickServices';
    code: number;
    quicktasklist?: Array<{
      __typename?: 'QuickService';
      id: string;
      task: string;
      department: string;
      qty_by_guest: number;
    } | null> | null;
  } | null;
};

export type GuestVerificationMutationVariables = Exact<{
  guestName: Scalars['String'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
}>;

export type GuestVerificationMutation = {
  __typename?: 'Mutation';
  verifyGuest?: {
    __typename?: 'Response';
    code: string;
    message: string;
    guest_id?: number | null;
    guest_name?: string | null;
    property_id?: string | null;
  } | null;
};

export const GetDineInCategoriesDocument = gql`
  query getDineInCategories($propertyID: String!) {
    getDineInCategories(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
    }
  }
`;

/**
 * __useGetDineInCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDineInCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInCategoriesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export function useGetDineInCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export type GetDineInCategoriesQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesQuery
>;
export type GetDineInCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesLazyQuery
>;
export type GetDineInCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetDineInCategoriesQuery,
  GetDineInCategoriesQueryVariables
>;
export const DineInListDocument = gql`
  query DineInList($propertyID: String!) {
    getGuestEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      extraOptions {
        name
        price
      }
      is_Activated
    }
  }
`;

/**
 * __useDineInListQuery__
 *
 * To run a query within a React component, call `useDineInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDineInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDineInListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDineInListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DineInListQuery, DineInListQueryVariables>(
    DineInListDocument,
    options
  );
}
export function useDineInListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DineInListQuery,
    DineInListQueryVariables
  >(DineInListDocument, options);
}
export type DineInListQueryHookResult = ReturnType<typeof useDineInListQuery>;
export type DineInListLazyQueryHookResult = ReturnType<
  typeof useDineInListLazyQuery
>;
export type DineInListQueryResult = ApolloReactCommon.QueryResult<
  DineInListQuery,
  DineInListQueryVariables
>;
export const FeedbackListDocument = gql`
  query FeedbackList($propertyID: String!) {
    getFeedbackTypeList(propertyID: $propertyID) {
      id
      name
    }
  }
`;

/**
 * __useFeedbackListQuery__
 *
 * To run a query within a React component, call `useFeedbackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useFeedbackListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export function useFeedbackListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export type FeedbackListQueryHookResult = ReturnType<
  typeof useFeedbackListQuery
>;
export type FeedbackListLazyQueryHookResult = ReturnType<
  typeof useFeedbackListLazyQuery
>;
export type FeedbackListQueryResult = ApolloReactCommon.QueryResult<
  FeedbackListQuery,
  FeedbackListQueryVariables
>;
export const SubmitFeedbackDocument = gql`
  mutation submitFeedback($input: FeedbackInput!) {
    createFeedback(feedbackInput: $input) {
      code
      message
    }
  }
`;
export type SubmitFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackMutationHookResult = ReturnType<
  typeof useSubmitFeedbackMutation
>;
export type SubmitFeedbackMutationResult =
  ApolloReactCommon.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >;
export const HotelDetailsDocument = gql`
  query HotelDetails($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      about {
        desc
        img
      }
      dining {
        desc
        id
        img
        name
      }
      dir_con {
        address
        email
        img
        phone
      }
      hotel_rooms {
        desc
        id
        img
        room_type
      }
      hotel_services {
        desc
        id
        img
        name
      }
      promotion_desc
      feedback_desc
      hotel_services_image
      rooms_image
      directory_description
      dining_image
    }
  }
`;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useHotelDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export function useHotelDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export type HotelDetailsQueryHookResult = ReturnType<
  typeof useHotelDetailsQuery
>;
export type HotelDetailsLazyQueryHookResult = ReturnType<
  typeof useHotelDetailsLazyQuery
>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<
  HotelDetailsQuery,
  HotelDetailsQueryVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder($orderInput: OrderInput!) {
    upsertOrder(orderInput: $orderInput) {
      id
    }
  }
`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<
  typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const MyOrdersDocument = gql`
  query MyOrders($guestID: Int!, $propertyID: String!) {
    getGuestOrders(guestID: $guestID, propertyID: $propertyID) {
      cart_items {
        dineInItem_id
        extras {
          name
          price
        }
        quantity
        dineInItem_name
        dineInItem_price
      }
      property_id
      id
      room_id
      status
      total
      guest_id
      guest_name
      created_at
      order_id
    }
  }
`;

/**
 * __useMyOrdersQuery__
 *
 * To run a query within a React component, call `useMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrdersQuery({
 *   variables: {
 *      guestID: // value for 'guestID'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useMyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export function useMyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export type MyOrdersQueryHookResult = ReturnType<typeof useMyOrdersQuery>;
export type MyOrdersLazyQueryHookResult = ReturnType<
  typeof useMyOrdersLazyQuery
>;
export type MyOrdersQueryResult = ApolloReactCommon.QueryResult<
  MyOrdersQuery,
  MyOrdersQueryVariables
>;
export const GetGuestPromotionsDocument = gql`
  query getGuestPromotions($propertyID: String!, $currDate: String!) {
    getGuestPromotions(propertyID: $propertyID, currDate: $currDate) {
      id
      name
      end_date
      start_date
      property_id
      description
      banner_image
      is_Activated
    }
  }
`;

/**
 * __useGetGuestPromotionsQuery__
 *
 * To run a query within a React component, call `useGetGuestPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestPromotionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      currDate: // value for 'currDate'
 *   },
 * });
 */
export function useGetGuestPromotionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >(GetGuestPromotionsDocument, options);
}
export function useGetGuestPromotionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >(GetGuestPromotionsDocument, options);
}
export type GetGuestPromotionsQueryHookResult = ReturnType<
  typeof useGetGuestPromotionsQuery
>;
export type GetGuestPromotionsLazyQueryHookResult = ReturnType<
  typeof useGetGuestPromotionsLazyQuery
>;
export type GetGuestPromotionsQueryResult = ApolloReactCommon.QueryResult<
  GetGuestPromotionsQuery,
  GetGuestPromotionsQueryVariables
>;
export const PropertyDetailsDocument = gql`
  query propertyDetails($domain: String!) {
    getPropertyByDomain(domain: $domain) {
      id
      name
      img
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
      chat_api_url
      currency
      background_color
    }
  }
`;

/**
 * __usePropertyDetailsQuery__
 *
 * To run a query within a React component, call `usePropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyDetailsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function usePropertyDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >(PropertyDetailsDocument, options);
}
export function usePropertyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >(PropertyDetailsDocument, options);
}
export type PropertyDetailsQueryHookResult = ReturnType<
  typeof usePropertyDetailsQuery
>;
export type PropertyDetailsLazyQueryHookResult = ReturnType<
  typeof usePropertyDetailsLazyQuery
>;
export type PropertyDetailsQueryResult = ApolloReactCommon.QueryResult<
  PropertyDetailsQuery,
  PropertyDetailsQueryVariables
>;
export const PropertyServicesDocument = gql`
  query propertyServices($property_id: String!) {
    getServices(propertyID: $property_id) {
      code
      tasklist
    }
  }
`;

/**
 * __usePropertyServicesQuery__
 *
 * To run a query within a React component, call `usePropertyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePropertyServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export function usePropertyServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export type PropertyServicesQueryHookResult = ReturnType<
  typeof usePropertyServicesQuery
>;
export type PropertyServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyServicesLazyQuery
>;
export type PropertyServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyServicesQuery,
  PropertyServicesQueryVariables
>;
export const PropertyServicesMutationDocument = gql`
  mutation propertyServicesMutation($input: ServiceInput!) {
    requestService(serviceInput: $input) {
      code
      message
    }
  }
`;
export type PropertyServicesMutationMutationFn =
  ApolloReactCommon.MutationFunction<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;

/**
 * __usePropertyServicesMutationMutation__
 *
 * To run a mutation, you first call `usePropertyServicesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertyServicesMutationMutation, { data, loading, error }] = usePropertyServicesMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePropertyServicesMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >(PropertyServicesMutationDocument, options);
}
export type PropertyServicesMutationMutationHookResult = ReturnType<
  typeof usePropertyServicesMutationMutation
>;
export type PropertyServicesMutationMutationResult =
  ApolloReactCommon.MutationResult<PropertyServicesMutationMutation>;
export type PropertyServicesMutationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;
export const RequestServicesDocument = gql`
  mutation requestServices($input: [ServiceInput!]) {
    requestServices(servicesInput: $input) {
      code
      message
    }
  }
`;
export type RequestServicesMutationFn = ApolloReactCommon.MutationFunction<
  RequestServicesMutation,
  RequestServicesMutationVariables
>;

/**
 * __useRequestServicesMutation__
 *
 * To run a mutation, you first call `useRequestServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestServicesMutation, { data, loading, error }] = useRequestServicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestServicesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >(RequestServicesDocument, options);
}
export type RequestServicesMutationHookResult = ReturnType<
  typeof useRequestServicesMutation
>;
export type RequestServicesMutationResult =
  ApolloReactCommon.MutationResult<RequestServicesMutation>;
export type RequestServicesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >;
export const GetTaskHistoryDocument = gql`
  query getTaskHistory($propertyID: String!, $guestID: Int!, $roomID: String!) {
    getTaskHistory(
      propertyID: $propertyID
      guestID: $guestID
      roomID: $roomID
    ) {
      task_list
      task_name
      status
      quantity
      created_time
    }
  }
`;

/**
 * __useGetTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskHistoryQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      guestID: // value for 'guestID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGetTaskHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >(GetTaskHistoryDocument, options);
}
export function useGetTaskHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >(GetTaskHistoryDocument, options);
}
export type GetTaskHistoryQueryHookResult = ReturnType<
  typeof useGetTaskHistoryQuery
>;
export type GetTaskHistoryLazyQueryHookResult = ReturnType<
  typeof useGetTaskHistoryLazyQuery
>;
export type GetTaskHistoryQueryResult = ApolloReactCommon.QueryResult<
  GetTaskHistoryQuery,
  GetTaskHistoryQueryVariables
>;
export const PropertyQuickServicesDocument = gql`
  query propertyQuickServices($propertyID: String!) {
    getQuickServices(propertyID: $propertyID) {
      code
      quicktasklist {
        id
        task
        department
        qty_by_guest
      }
    }
  }
`;

/**
 * __usePropertyQuickServicesQuery__
 *
 * To run a query within a React component, call `usePropertyQuickServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyQuickServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyQuickServicesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function usePropertyQuickServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >(PropertyQuickServicesDocument, options);
}
export function usePropertyQuickServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >(PropertyQuickServicesDocument, options);
}
export type PropertyQuickServicesQueryHookResult = ReturnType<
  typeof usePropertyQuickServicesQuery
>;
export type PropertyQuickServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyQuickServicesLazyQuery
>;
export type PropertyQuickServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyQuickServicesQuery,
  PropertyQuickServicesQueryVariables
>;
export const GuestVerificationDocument = gql`
  mutation guestVerification(
    $guestName: String!
    $propertyID: String!
    $roomID: String!
  ) {
    verifyGuest(
      guestName: $guestName
      propertyID: $propertyID
      roomID: $roomID
    ) {
      code
      message
      guest_id
      guest_name
      property_id
    }
  }
`;
export type GuestVerificationMutationFn = ApolloReactCommon.MutationFunction<
  GuestVerificationMutation,
  GuestVerificationMutationVariables
>;

/**
 * __useGuestVerificationMutation__
 *
 * To run a mutation, you first call `useGuestVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestVerificationMutation, { data, loading, error }] = useGuestVerificationMutation({
 *   variables: {
 *      guestName: // value for 'guestName'
 *      propertyID: // value for 'propertyID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGuestVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >(GuestVerificationDocument, options);
}
export type GuestVerificationMutationHookResult = ReturnType<
  typeof useGuestVerificationMutation
>;
export type GuestVerificationMutationResult =
  ApolloReactCommon.MutationResult<GuestVerificationMutation>;
export type GuestVerificationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >;
