import { Box, Chip, SxProps, Theme } from '@mui/material';
import { useAuth } from '../../../store';
import { useQuickServiceAlert, useServiceAlert } from '../../../utils';
import {
  QuickService,
  usePropertyQuickServicesQuery,
  usePropertyServicesMutationMutation
} from '../../../generated/graphql';
import { useEffect, useState } from 'react';

interface QuickServicesProps {
  sx?: SxProps<Theme>;
}

function QuickServices({ sx }: QuickServicesProps) {
  const serviceAlert = useServiceAlert(() => {});
  const quickServiceAlert = useQuickServiceAlert();
  const guest = useAuth((state: any) => state.guest);
  const [tasks, setTasks] = useState<Array<QuickService | null>>([]);

  const { data: quickServices } = usePropertyQuickServicesQuery({
    variables: {
      propertyID: guest?.property_id
    }
  });

  useEffect(() => {
    const srvces = quickServices?.getQuickServices?.quicktasklist || [];
    if (srvces.length) {
      setTasks([...srvces]);
    }
  }, [quickServices?.getQuickServices?.quicktasklist]);

  const [requestService] = usePropertyServicesMutationMutation();

  const clickHandler = async (service: any) => {
    const { isConfirmed, value } = await quickServiceAlert(service);

    if (!isConfirmed || !value) return;

    const [quantity] = value;

    try {
      const res = await requestService({
        variables: {
          input: {
            quantity,
            property_id: guest?.property_id,
            room_id: guest?.room_id,
            task_id: service?.id
          }
        }
      });
      if (res.data?.requestService?.code === '200') {
        serviceAlert('Submitted Successfully');
      } else {
        serviceAlert(
          res.data?.requestService?.message ||
            'Something went wrong! Please contact support'
        );
      }

      // Success alert
    } catch (error) {
      serviceAlert(`Something went wrong! Please try again`);
    }
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      {tasks?.map((service: any, index: number) => {
        return (
          <Chip
            key={service.id}
            color="primary"
            variant="filled"
            label={service.task}
            sx={{
              mt: 1,
              ml: index !== 0 ? 1 : 0
            }}
            onClick={() => clickHandler({ ...service })}
          />
        );
      })}
    </Box>
  );
}

export { QuickServices };
