import {
  AlcoholIcon,
  BreakfastIcon,
  ConciergeIcon,
  DessertIcon,
  DrinksIcon,
  EngineeringIcon,
  FoodIcon,
  HotDrinksIcon,
  HousekeepingIcon,
  ItIcon,
  MainsIcon,
  SaladIcon,
  SoupIcon,
  StarterIcon,
} from "../components/Icons";
import { EggIcon } from "../components/Icons/EggIcon";
import { FishIcon } from "../components/Icons/FishIcon";
import { FrontDeskIcon } from "../components/Icons/FrontDeskIcon";
import { MilkIcon } from "../components/Icons/MilkIcon";
import { PeanutsIcon } from "../components/Icons/PeanutsIcon";
import { RoomServiceIcon } from "../components/Icons/RoomServiceIcon";
import { SecurityIcon } from "../components/Icons/SecurityIcon";
import { ShelfishIcon } from "../components/Icons/ShelfishIcon";
import { SoybeansIcon } from "../components/Icons/SoybeansIcon";
import { TreenutsIcon } from "../components/Icons/TreenutsIcon";
import { WheatIcon } from "../components/Icons/WheatIcon";

export const SERVICES = [
  {
    name: "Concierge",
    icon: <ConciergeIcon />,
  },
  {
    name: "IT",
    icon: <ItIcon />,
  },
  {
    name: "Engineering",
    icon: <EngineeringIcon />,
  },
  {
    name: "F & B",
    icon: <FoodIcon />,
  },
  {
    name: "Housekeeping",
    icon: <HousekeepingIcon />,
  },
];

export const SERVICES_ICONS = {
  "Front Office": <FrontDeskIcon />,
  Engineering: <EngineeringIcon />,
  Housekeeping: <HousekeepingIcon />,
  Concierge: <ConciergeIcon />,
  "Room Service": <RoomServiceIcon />,
  Security: <SecurityIcon />,
  "Information Technology": <ItIcon />,
};

export const IN_ROOM_DINING_ICONS = {
  Breakfast: <BreakfastIcon />,
  Starters: <StarterIcon />,
  Salads: <SaladIcon />,
  Soups: <SoupIcon />,
  Mains: <MainsIcon />,
  Desserts: <DessertIcon />,
  Drinks: <DrinksIcon />,
  "Hot Drinks": <HotDrinksIcon />,
  Alcohol: <AlcoholIcon />,
};

export const IN_ROOM_CATEGORY_ICONS = {
  BreakfastIcon: <BreakfastIcon />,
  StarterIcon: <StarterIcon />,
  SaladIcon: <SaladIcon />,
  SoupIcon: <SoupIcon />,
  MainsIcon: <MainsIcon />,
  DessertIcon: <DessertIcon />,
  DrinksIcon: <DrinksIcon />,
  HotDrinksIcon: <HotDrinksIcon />,
  AlcoholIcon: <AlcoholIcon />,
};

export const ALLERGEN_ICONS={
  SoybeansIcon:<SoybeansIcon/>,
  PeanutsIcon:<PeanutsIcon/>,
  WheatIcon:<WheatIcon/>,
  TreenutsIcon:<TreenutsIcon/>,
  ShelfishIcon:<ShelfishIcon/>,
  MilkIcon:<MilkIcon/>,
  FishIcon:<FishIcon/>,
  EggIcon:<EggIcon/>,
};
export const hotelInformation = [
  {
    path: "",
    name: "About Hotel",
    image: "/../assets/hotel-information/about-hotel.webp",
    desc: "Hotel is located right in the centre of the capital city, in a modern district of business, commerce and entertainment. The hotel stands next to the river, near the Old Town with its major sites of attraction: the Cathedral, Palace, Castle, as well as streets so popular among the city guests and local inhabitants.",
  },
  {
    path: "/:roomID/hotel-rooms",
    name: "Hotel Rooms",
    image: "/../assets/hotel-information/hotel-rooms.jpg",
  },
  {
    path: "",
    name: "Directions & Contacts",
    image: "/../assets/hotel-information/direction.jpeg",
    desc: "Address: 1801-03, 18/F, East Town Building,Hong Kong,E-mail: mailto:sales@hotlync.com",
  },
  {
    path: "/:roomID/hotel-services",
    name: "Hotel Services",
    image: "/../assets/hotel-information/hotel-service.jpg",
  },
];

export const hotelRooms = [
  {
    path: "",
    name: "Quartz Studio",
    image: "/assets/hotel-rooms/Quartz.png",
    desc: "The hotel's standard rooms include standard bed, small seating area and bathroom.Room size: 30 sq. m",
  },
  {
    path: "",
    name: "Amber Twin Room",
    image: "/assets/hotel-rooms/AmberTwin.png",
    desc: "Amber Twin offer luxurious king beds, 47-inch flat-panel HDTV with media player Bose Sound Wave System with iPod and iPhone docking station, large closets, master bath, and a guest bath. For your comfort and convenience.",
  },
  {
    path: "",
    name: "Emerlant Suite",
    image: "/assets/hotel-rooms/Emerland.png",
    desc: "The hotel's double bed rooms include double bed, big seating area and bathroom.",
  },
  {
    path: "",
    name: "Amber Deluxe ",
    image: "/assets/hotel-rooms/AmberDeluxe.png",
    desc: "Amber suites are larger than standard rooms. Coffee/tea making facilities, complimentary newspaper, trouser press, direct-dial telephone, hairdryer, wake-up service and television. All rooms have dressing table with chair, lounge chair and en-suite with bath and shower.",
  },
  {
    path: "",
    name: "Presidential Suite",
    image: "/assets/hotel-rooms/presidential.jpg",
    desc: "The most luxurious and impressive type of our suites is located on the 20th floor and offers breathtaking views on the city and unsurpassed luxury during your stay. Our presidential suite consists of a spacious living room, one large master bedroom with private sauna, a second bedroom, a private study, a dining room, a bar as well as a kitchen. Each bedroom has its en-suite bathroom with jacuzzi.",
  },
];

export const HotelSer = [
  {
    path: "",
    name: "Check-In & Check-Out",
    image: "/../assets/Chat.png",
    desc: " Check-in: 2:00pm ,Check-out: 12 noon, late check-out is subject to availability and surcharge. For any further inquiries please do not hesitate to contact the reception desk on extension",
  },
  {
    path: "",
    name: "Room Services",
    image: "/../assets/hotel-information/hotel-rooms.jpg",
    desc: "For your convenience, our room service is available 24 hours. The menu is located on your room desk. Please dial Room Service on the extension  ",
  },

  {
    path: "",
    name: "Mini Bar",
    image: "/../assets/InRoomDining.png",
    desc: "Offers you a wide range of soft drinks, spirits, and snacks. Please find the item list in the mini bar cabinet. ",
  },

  {
    path: "",
    name: "Housekeeping",
    image: "/../assets/Chat.png",
    desc: "Housekeeping is available 24 hours a day. If you have any special requests and questions, please contact housekeeping on extension  ",
  },

  {
    path: "",
    name: "Room Cleaning",
    image: "/../assets/Services.png",
    desc: "Housekeeping service is provided 24 hours. If you do not wish to be disturbed during the room cleaning hours, please place the “Do Not Disturb” card on the door knob outside the room. ",
  },

  {
    path: "",
    name: "Guest Loan Items",
    image: "/../assets/feedback.jpeg",
    desc: " Housekeeping provides the following equipment: - Iron and Ironing Board - Converters / Mobile Chargers - Shaving Kits / Dental Kit / Combs - Additional Pillows / Blankets / Extra bed - Express shoe shine service If you have any special requests or inquiries, please dial extension (4) for housekeeping assistance. ",
  },

  {
    path: "",
    name: "Laundry",
    image: "/../assets/Chat.png",
    desc: "We offer same-day laundry service daily. Turn your items in by 9:00 am and you will have them returned the same day by 6:00 pm. ",
  },

  {
    path: "",
    name: "Luggage Service",
    image: "/../assets/HotelInformation.png",
    desc: "Dial (3) for the Concierge to help you with your baggage. ",
  },

  {
    path: "",
    name: "Turndown Services",
    image: "/../assets/Services.png",
    desc: "In respect to your privacy, the turndown services will only be carried out in the evening upon request.  ",
  },

  {
    path: "",
    name: "Safe-Deposite Boxes",
    image: "/../assets/HotelInformation.png",
    desc: "We cannot assume responsibility for any cash or valuables left in guestrooms, so we strongly encourage you to use the hotel’s safe deposit boxes. For further help, please dial (0) for the Operator and we will be happy to assist you. ",
  },

  {
    path: "",
    name: "Babysitting",
    image: "/../assets/Services.png",
    desc: "To arrange for a babysitter, kindly call the Guest Relations desk at extension (6), 24 hours prior to the required time. ",
  },

  {
    path: "",
    name: "Doctor",
    image: "/../assets/Chat.png",
    desc: " For a doctor or dentist, our Concierge can provide you with a list of local professional doctors and medical centers. ",
  },

  {
    path: "",
    name: "Auto Rental",
    image: "/../assets/feedback.jpeg",
    desc: "For a ride around town, an airport transfer or if you are looking to rent a car, we will be happy to get you there by private taxi, limousine, or local car service. For information or to make reservations, please dial (3) for the Concierge desk. ",
  },

  {
    path: "",
    name: "Currency Exchange",
    image: "/../assets/HotelInformation.png",
    desc: "Foreign currency may be exchanged at the Front Desk 24 hours a day. ",
  },

  {
    path: "",
    name: "Wake-Up Calls",
    image: "/../assets/Chat.png",
    desc: " Please dial (0) for Operator and we will get your day off to a great start. You can also set your auto wake-up call by using the shortcut button, your IP phone in the room. ",
  },

  {
    path: "",
    name: "Figure 8 Health Club",
    image: "/../assets/feedback.jpeg",
    desc: "Rejuvenate your body and relax your mind in Figure 8 on the 9th floor. Our fully-equipped state of the art gym and our exceptional recreation center will help you to unwind from a stressful day. Facilities include: - Multipurpose Gymnasium  - Treatment Rooms / Sauna / Steam Room - Ladies Beauty Salon ",
  },

  {
    path: "",
    name: "Cristial Spa",
    image: "/../assets/Services.png",
    desc: " Discover the power of our pampering treatments, the gentle water of our pool as well as the thermal qualities of our steam room and sauna. Unwind and de-stress with the amazing selection of massage and body treatments that will make you feel completely recharged and look more radiant. To get more information on treatments, memberships or other services of Cristal Spa please call the Cristal Spa reception on 7901 ",
  },

  {
    path: "/:roomID/hotel-services/dining",
    name: "Dining",
    image: "/../assets/hotel-information/hotel-service.jpg",
    desc: "",
  },
];

export const Dining = [
  {
    path: "",
    name: "Restaurants & Bars",
    image: "/../assets/hotel-information/hotel-service.jpg",
    desc: "At the Cristal Hotel, we offer a variety of irresistible meals to satisfy your every craving from a quick bite on the run to a relaxing meal in your room or a sumptuous International buffet. ",
  },
  {
    path: "",
    name: "Lobby Cafe",
    image: "/../assets/hotel-information/direction.jpeg",
    desc: " Located in the lobby area, Blendz is an ideal rendezvous point for guests and residents to meet friends or clients. One may enjoy a bite to eat whilst listening to the perfectly blended selection of chill-out vibes or simply enjoy the contemporary décor, sophisticated background music, delicious food, decadent drinks, and a cosmopolitan ambiance.  Open: 24 hours Location: Ground floor ",
  },

  {
    path: "",
    name: "All-Day Dining Restaurant",
    image: "/../assets/hotel-information/hotel-service.jpg",
    desc: "  Viewz offers you a wide selection of International buffets and brunches plus a wide selection of tempting beverages. Come and experience the chic and contemporary ambiance, with magnificent views of Abu Dhabi. Viewz restaurant is the perfect venue for business and family gatherings or simply a place to relax with friends.  Open: Daily for Breakfast, Lunch, Dinner & Supper from 06:00 am to 11:00 pm Location: 18th floor Dress Code: Smart Casual ",
  },

  {
    path: "",
    name: "The G.O.A.T. and Bambu",
    image: "/../assets/Services.png",
    desc: " This casual & stylish venue offers you the best relaxation and entertainment after a long day in bustling Abu Dhabi. The G.O.A.T. offers a wide selection of popular labels, signature cocktails, and a good range of beers on tap for an affordable price. Cristal Viewz also brings to the spotlight its LIVE entertainment by its resident band. Darts & pool games are also available. Bambu, the vibrant South American / Asian Fusion Restaurant hosts the finest cuisine and the best Tiki cocktails in town, with live entertainment and tropical surroundings Open Daily:11:00 am to 3:00 am Location: 17th floor with a full view of the city skyline Dress Code: Smart Casual ",
  },

  {
    path: "",
    name: "Directory",
    image: "/../assets/Chat.png",
    desc: " Ground Floor , Reception , Blendz Café,  ES Ladies Salon, Mezzanine, Ruby Night Club , Taste of Korea Restaurant ,1st - 16th Floor , Rooms ,17th Floor  ,On Seventeen Bar ,18th Floor , Viewz Restaurant ,Cristal Lounge ,Meeting Rooms ,Executive Office ,Prayer Rooms ,19th Floor ,Figure 8 Health Club ,Cristal Spa - Moroccan Bath ",
  },
];

export const menuTitles = [
  { title: "Breakfast" },
  { title: "Starters" },
  { title: "Salads" },
  { title: "Soups" },
  { title: "Mains" },
  { title: "Desserts" },
  { title: "Drinks" },
  { title: "Hot Drinks" },
  { title: "Alcohol" },
];

export const menuItems = [
  {
    id: 1,
    title: "Continental Breakfast",
    price: 20,
    category: "VEGAN",
    shortDesc:
      "Build your own continental breakfast by choosing options below.",
    image: "/assets/breakfast/c_bf.jpg",
    extraItems: ["Fruit Salad", "Cheese Plate", "White Tost", "Brown Tost"],
  },
  {
    id: 2,
    title: "Pecan Pancakes",
    price: 8,
    category: "VEGAN",
    shortDesc:
      "Three Buttermilk Pancakes loaded with pecans and served with real Butter and a warm bottle of 100% Pure Natural Syrup",
    image: "/assets/breakfast/pecan_bf.jpg",
    extraItems: [],
  },
  {
    id: 3,
    title: "French Toast",
    price: 10,
    category: "VEGAN",
    shortDesc: "Build your own.",
    image: "/assets/breakfast/french_tost.jpg",
    extraItems: [],
  },
  {
    id: 4,
    title: "Oatmeal Breakfast",
    price: 16,
    category: "VEGAN",
    shortDesc: "Build your own.",
    image: "/assets/breakfast/oat_bf.jpg",
    extraItems: ["Fried Apples", "Slice Banana", "Pecans"],
  },
];
