import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import * as React from 'react';
import { isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth, useCart } from '../../store';
import { FullScreenLoader } from '../../components';
import { imageFallback, useSweetAlert } from '../../utils';
import { useMenuItem } from '../InRoomDining/room-dining-hook';
import { useCreateOrderMutation } from '../../generated/graphql';
import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import { CustomButton, HeaderLayout, Layout, Paper } from '../InRoomDining';
import useInfo from '../Dashboard/components/InfoCard';

interface FoodItemCardProps {
  item: any;
  index: number;
}
type ExtraOptions = {
  name: string;
  price: string;
};

type Cart = {
  id: string;
  count: number;
  extra: ExtraOptions[];
};
export const FoodItemCard = ({ item, index }: FoodItemCardProps) => {
  const { currency } = useInfo();
  const { name, price, img, isVegan } = item;

  const [open, setOpen] = React.useState(false);
  const cart = useCart((state: any) => state.cart);
  const addToCartIndex = useCart((state: any) => state.addToCartIndex);
  const removeFromCartIndex = useCart(
    (state: any) => state.removeFromCartIndex
  );
  const updateCartItemIndexExtra = useCart(
    (state: any) => state.updateCartItemIndexExtra
  );

  const currentItemInCart = cart[index];
  const itemsInCart = currentItemInCart.count;
  const extraItems = currentItemInCart?.extra;

  const toggleDrawer = () => setOpen(state => !state);

  const updateCartItemExtra = (extra: Array<any>) =>
    updateCartItemIndexExtra(index, extra);

  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
      <Box
        src={img}
        alt={name}
        width={70}
        height={70}
        component="img"
        onError={imageFallback}
        style={{ borderRadius: '10px', objectFit: 'cover' }}
      />
      <Box sx={{ ml: 1.2, flex: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {name}
        </Typography>
        <Box>
          {extraItems &&
            extraItems.map((extra: ExtraOptions) => {
              return (
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', display: 'inline' }}
                >
                  {extra?.name + ', '}
                </Typography>
              );
            })}
        </Box>

        {/* {extraItems && <Typography variant="body2">{extraItems}</Typography>} */}
        {extraItems && (
          <Button variant="text" sx={{ p: 0 }} onClick={toggleDrawer}>
            Customize
          </Button>
        )}
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {currency} {price}
        </Typography>

        <Box
          component="img"
          onError={imageFallback}
          style={{ marginTop: '4px' }}
          alt={isVegan ? 'veg' : 'non-veg'}
          src={`/assets/${isVegan ? 'veg' : 'non-veg'}.png`}
        />
      </Box>
      {!Boolean(itemsInCart) ? (
        <CustomButton
          size="small"
          variant="outlined"
          sx={{ alignSelf: 'center' }}
          onClick={() => addToCartIndex(index)}
        >
          Add
        </CustomButton>
      ) : (
        <ButtonGroup
          size="small"
          variant="contained"
          sx={{
            border: 'none',
            color: '#ffffff',
            alignSelf: 'center',
            backgroundColor: 'primary.main'
          }}
        >
          <IconButton
            size="small"
            onClick={() => addToCartIndex(index)}
            sx={{ color: '#ffffff' }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <CustomButton
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{ border: 'none !important' }}
          >
            {itemsInCart}
          </CustomButton>

          <IconButton
            size="small"
            onClick={() => removeFromCartIndex(index)}
            sx={{ color: '#ffffff' }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </ButtonGroup>
      )}

      {item?.extraOptions?.length && (
        <ExtraItemDrawer
          open={open}
          currentItem={item}
          extraItems={extraItems}
          buttonText={'Update Cart'}
          toggleDrawer={toggleDrawer}
          updateCartItemExtra={updateCartItemExtra}
        />
      )}
    </Paper>
  );
};

function MyCart() {
  const { currency } = useInfo();
  const sweetAlert = useSweetAlert();
  const guest = useAuth((state: any) => state.guest);
  const { roomID } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useMenuItem();
  const cart = useCart((state: any) => state.cart);
  const [upsertOrder, { loading: loadingCreateOrder }] =
    useCreateOrderMutation();
  const resetCart = useCart((state: any) => state.resetCart);

  const extraOptions = cart
    ?.map((v: Cart) => {
      if (v?.extra) {
        return v?.extra;
      }
      return { price: '0' };
    })
    .flat();
  const total = extraOptions?.reduce(
    (acc: number, curr: ExtraOptions) => acc + parseFloat(curr?.price),
    0
  );

  // Computed states
  const totalItems = React.useMemo(
    () => cart.reduce((prev: number, current: any) => prev + current.count, 0),
    [cart]
  );

  const foodItemMap = React.useMemo(() => {
    if (!data) return {};
    return data?.getGuestEdibles?.reduce((acc: any, current: any) => {
      acc[current.id] = current;
      return acc;
    }, {});
  }, [data]);

  const cartItems = React.useMemo(() => {
    if (!data || !cart || !cart.length) return [];

    return cart.map((cartItem: any) => {
      const item = { ...foodItemMap[cartItem.id] };
      item.quantity = item.count;

      return item;
    });
  }, [foodItemMap, cart, data]);

  const totalPrice = React.useMemo(() => {
    if (isEmpty(foodItemMap)) return 0;

    return cart.reduce((prev: number, current: any) => {
      const currentItem = foodItemMap[current.id];
      const itemCost = currentItem.price * current.count;
      return prev + itemCost;
    }, 0);
  }, [cart, foodItemMap]);

  const handleOrder = async () => {
    try {
      const orderData = cart.map((item: any) => {
        const { name, price } = foodItemMap[item.id];

        return {
          quantity: item.count,
          dineInItem_name: name,
          dineInItem_price: price,
          dineInItem_id: item.id,
          ...(item.extra ? { extras: item.extra } : {})
        };
      });

      const orderInput = {
        cart_items: orderData,
        property_id: guest?.property_id,
        guest_id: guest?.guest_id,
        guest_name: guest?.guest_name,
        room_id: parseInt(guest?.room_id),
        total: totalPrice + total
      };

      const res = await upsertOrder({
        variables: {
          orderInput
        }
      });

      if (res.data?.upsertOrder?.id) {
        sweetAlert
          .fire({
            text: 'Order Created Successfully',
            icon: 'success',
            timer: 2000
          })
          .then(() => {
            resetCart();
            navigate(`/${roomID}`);
          });
      } else {
        sweetAlert.fire({
          text: 'Order Not Created',
          icon: 'error'
        });
      }
    } catch (err) {
      sweetAlert.fire({
        text: `${err}`,
        icon: 'error'
      });
    }
  };

  return (
    <>
      <Layout>
        <HeaderLayout title="View Cart" />

        {/* TODO: Make a separate hidden scroll component*/}
        <Box
          sx={{
            padding: '5px',
            flex: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          <Typography variant="body2">{totalItems} Items</Typography>

          {cartItems.map((item: any, index: number) => {
            return <FoodItemCard key={index} index={index} item={item} />;
          })}

          <Paper sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6">Billing Summary</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body2"
                sx={{ mt: 1, color: 'text.secondary' }}
              >
                Total Items ({totalItems})
              </Typography>

              <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold' }}>
                {currency} {totalPrice + total}
              </Typography>
            </Box>

            {/* TODO: Set whether property want to set the tax and service cost values */}
            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                sx={{ mt: 0.5, color: "text.secondary" }}
              >
                Service Cost
              </Typography>

              <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
                AED 0
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                sx={{ mt: 0.5, color: "text.secondary" }}
              >
                Tax
              </Typography>

              <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
                AED 0
              </Typography>
            </Box> */}

            <Divider sx={{ my: 1.5 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Total
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {currency} {totalPrice + total}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                *prices are inclusive of all taxes and government fees
              </Typography>
            </Box>
          </Paper>
        </Box>

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleOrder}
            disabled={totalItems === 0}
          >
            Make Order
          </Button>
        </Box>
      </Layout>

      {(loading || loadingCreateOrder) && <FullScreenLoader />}
    </>
  );
}

export default MyCart;
