import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Link, Typography } from "@mui/material";

import {
  usePropertyDetailsQuery,
  useHotelDetailsLazyQuery,
} from "../../../generated/graphql";
import { CardPaper } from "./CardPaper";
import { imageFallback, useDomain } from "../../../utils";

const useInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  const descriptionString = propertyMeta?.getMeta?.about?.desc;
  const imgUrl = propertyDetails?.getPropertyByDomain?.img;
  const currency=propertyDetails?.getPropertyByDomain?.currency;

  return {
    currency,
    imgUrl,
    descriptionString,
    loading: loadingMeta || loadingProperty,
  };
};
 export default useInfo;
const InfoCard = () => {
  const navigate = useNavigate();
  const { imgUrl, loading, descriptionString } = useInfo();

  if (loading) {
    return (
      <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CardPaper sx={{ mt: 3, px: 3, py: 2.5 }}>
      <Box sx={{ mb: 1, textAlign: "center" }}>
        <Box
          height={100}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      {descriptionString && (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {descriptionString.slice(0, 160)}
          <Link
            underline="none"
            onClick={() => navigate("/hotel-information")}
            sx={{ ml: 0.5, fontSize: "inherit", fontWeight: 600 }}
          >
            Read More..
          </Link>
        </Typography>
      )}
    </CardPaper>
  );
};

export { InfoCard };
