import create from 'zustand';
import { isEmpty, isEqual } from 'lodash';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const GUEST_CART = 'GUEST_CART';

let store: any = (set: any) => ({
  cart: [],
  addToCart: (id: string, extra?: Array<any>) =>
    set((state: any) => {
      const currentItems = state.cart.filter((item: any) => item.id === id);

      const cartObject = { id, ...(extra ? { extra } : {}) };
      const currentItem = currentItems.find((item: any) => {
        const { count, ...itemWithoutCount } = item;

        return isEqual(itemWithoutCount, cartObject);
      });

      if (!currentItem) {
        state.cart.push({ id, count: 1, ...(extra ? { extra } : {}) });
      } else {
        currentItem.count += 1;
      }
    }),
  removeFromCart: (id: string) =>
    set((state: any) => {
      const itemIndex = state.cart.findIndex((item: any) => item.id === id);

      if (itemIndex === -1) return;

      const currentItem = state.cart[itemIndex];
      currentItem.count = Math.max(currentItem.count - 1, 0);
      if (currentItem.count === 0) {
        state.cart.splice(itemIndex, 1);
      }
    }),
  addToCartIndex: (index: number) =>
    set((state: any) => {
      const currentItem = state.cart[index];
      currentItem.count += 1;
    }),
  removeFromCartIndex: (index: number) =>
    set((state: any) => {
      const currentItem = state.cart[index];
      currentItem.count = Math.max(currentItem.count - 1, 0);
      if (currentItem.count === 0) {
        state.cart.splice(index, 1);
      }
    }),
  updateCartItemIndexExtra: (index: number, extra: Array<any>) =>
    set((state: any) => {
      const currentItem = state.cart[index];
      const currentItemId = currentItem.id;

      const cartItems = state.cart.filter(
        (item: any) => item.id === currentItemId
      );
      const cartObject = {
        id: currentItemId,
        ...(!isEmpty(extra) ? { extra } : {})
      };
      const duplicateItem = cartItems.find((item: any) => {
        const { count, ...itemWithoutCount } = item;

        return isEqual(itemWithoutCount, cartObject);
      });

      if (!duplicateItem) {
        if (isEmpty(extra)) {
          delete currentItem['extra'];
        } else {
          currentItem.extra = extra;
        }
      } else {
        state.cart.splice(index, 1);
        duplicateItem.count += currentItem.count;
      }
    }),
  resetCart: () =>
    set((state: any) => {
      state.cart = [];
    })
});

store = immer(store);
store = persist(store, {
  name: GUEST_CART
});

const useCart = create(store);

export { useCart };
